import { Fragment, useEffect, useState } from 'react'
import VideoPlayer from 'components/content/VideoPlayer'
import './content.css'

//Components
import ArticlePreview from 'components/content/ArticlePreview'
import SectionTitleBar from 'components/homescreen/SectionTitleBar'
import CategoriesSelector from 'components/content/CategoriesSelector'
import ContentFilterBar from 'components/content/ContentFilterBar'
import axios from 'axios'
import Spinner from 'components/Spinner'
import { checkToken } from 'state/action-creators'

//REDUX
import { connect } from 'react-redux'
import Pagination from 'react-js-pagination'

interface contentDataTypes {
  id: number
  title: string
  subtitle: string
  content: string
  reading_time: number
  goal: {
    id: number
    name: string
  }
  category: {
    id: number
    title: string
  }[]
  image: string
}

interface videoDataTypes {
  id: number
  title: string
  subtitle: string
  link: string
  reading_time: number
  goal: {
    id: number
    name: string
  }
  category: {
    id: number
    title: string
  }[]
}

interface ContentProps {
  state: any
  checkToken: () => void
}

interface ContentType {
  content?: contentDataTypes[]
  videos?: videoDataTypes[]
  categories?: any
  totalPages: number
  totalItems: number
  goals: any
}

const Content = (props: ContentProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const [contentState, setContentState] = useState<ContentType>({
    content: undefined,
    categories: undefined,
    videos: undefined,
    totalPages: 0,
    totalItems: 0,
    goals: undefined,
  })
  const { user } = state
  const { categories, content, videos, totalItems, goals } = contentState
  const [loading, setLoading] = useState(true)
  const [storeLink, setStoreLink] = useState<any>()

  const [page, setPage] = useState(1)

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/content', {
      params: {
        page: page,
        page_size: 10,
      },
    })

    const res2 = await axios.get(process.env.REACT_APP_BASE_URL + '/video')
    const res3 = await axios.get(process.env.REACT_APP_BASE_URL + '/category')
    const res4 = await axios.get(process.env.REACT_APP_BASE_URL + '/goal', {
      params: { language_id: user?.language_id },
    })

    setContentState({
      ...contentState,
      content: res.data.data,
      categories: res3.data,
      videos: res2.data,
      totalPages: res.data.pagination.total_pages,
      totalItems: res.data.pagination.total_items,
      goals: res4.data,
    })

    const res6 = await axios.get(process.env.REACT_APP_BASE_URL + '/language', {
      params: { language_id: state.user?.language_id },
    })

    const storeLang = res6.data.find((value: any) => value.id === (state.user.language_id as '1'))
    setStoreLink(storeLang.store_link)
  }

  useEffect(() => {
    props.checkToken()
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner></Spinner>
      </div>
    )

  return (
    <div className="ath-container pb-40">
      <Fragment>
        <SectionTitleBar title={ct?.navContent} storeLink={storeLink} />
        <ContentFilterBar contentCategories={categories} goals={goals} />
        <CategoriesSelector contentCategories={categories} />

        <div className="articles-container">
          {content &&
            content.map((article: contentDataTypes) => {
              return (
                <ArticlePreview
                  key={article.id}
                  image={article.image}
                  header={article.title}
                  kicker={article.subtitle}
                  to={`/home/content/article/${article.id}`}
                />
              )
            })}

          {videos &&
            videos.map((video: videoDataTypes) => {
              return <VideoPlayer key={video.id} url={video.link} header={video.title} />
            })}


          {totalItems > 2 && (
            <Pagination
              totalItemsCount={totalItems}
              activePage={page}
              onChange={(newPage) => {
                setPage(newPage)
                setLoading(true)
              }}
              innerClass="pagination"
              itemClass="pagination-item bg-productPrimary"
              activeClass="pagination-item-active"
            />
          )}

        </div>
      </Fragment>
    </div>
  )
}


const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { checkToken })(Content)
