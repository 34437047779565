import ArticleBar from 'components/content/ArticleBar'
import ArticlePreview from 'components/content/ArticlePreview'
import VideoPlayer from 'components/content/VideoPlayer'
import { useRouteMatch } from 'react-router'
import axios from 'axios'
import { useEffect, useState } from 'react'

//REDUX
import { connect } from 'react-redux'
import Spinner from 'components/Spinner'
import Pagination from 'react-js-pagination'

const ContentGoal = ({ state }) => {
  const match = useRouteMatch()
  const goalId = match.params.id

  const [page, setPage] = useState(1)

  const [loading, setLoading] = useState(true)

  const [contentState, setContentState] = useState({
    content: undefined,
    categories: undefined,
    videos: undefined,
    totalPages: 0,
    totalItems: 0,
  })

  const { goal, content, videos, totalItems } = contentState

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/content', {
      params: { category: goalId, page: page, page_size: 10 },
    })
    const res2 = await axios.get(process.env.REACT_APP_BASE_URL + '/video', {
      params: { category: goalId },
    })
    const res3 = await axios.get(process.env.REACT_APP_BASE_URL + '/goal/' + goalId, {
      params: { language_id: state.user.language_id, id: goalId },
    })
    setContentState({
      ...contentState,
      content: res.data.data,
      goal: res3.data,
      videos: res2.data,
      totalPages: res.data.pagination.total_pages,
      totalItems: res.data.pagination.total_items,
    })
  }

  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  return loading ? (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  ) : (
    <>
      <div>
        <ArticleBar text={goal.name} to="/home/content" />
        <div className="articles-container">
          {content.length !== 0
            ? content.map((article) => {
                return (
                  <ArticlePreview
                    key={article.id}
                    image={article.image}
                    header={article.title}
                    kicker={article.subtitle}
                    to={`/home/content/article/${article.id}`}
                  />
                )
              })
            : 'Não há conteúdo para essa categoria.'}

          {videos.length !== 0 &&
            videos.map((video) => {
              return <VideoPlayer key={video.id} url={video.link} header={video.title} />
            })}
          <Pagination
            totalItemsCount={totalItems}
            activePage={page}
            onChange={(newPage) => {
              setPage(newPage)
              setLoading(true)
            }}
            innerClass="pagination"
            itemClass="pagination-item bg-productPrimary"
            activeClass="pagination-item-active"
          />
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ContentGoal)
